.button {
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #397b8e;
  display: flex;
  align-items: baseline;
  border: none;
  background: none;
  &:hover {
    color: #5ca6bc;
  }
  &:active {
    color: #397b8e;
  }
  &:disabled {
    color: #cfd4d9;
  }
}
